import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface updateSysAppConfigParam {
    switchVideo?:any
    imgCustomer?:any
    imgGongzong?:any
    switchCoinactivity?:any
    switchRechange?:any
}

export interface updateAdminWebConfigParam {
    needTagsView?:any
    showLogo?:any
    logoTitle?:any
    logoAdress?:any
}

export interface updateConfigParam {
    createTime?:any
    name?:any
    dltFlg?:any
    updateTime?:any
    id?:any
    columnKey?:any
    value?:any
    version?:any
    configTab?:any
    desc?:any
}

export interface updateAppDividendConfigParam {
    dividendWay?:any
    dividendLevel?:any
    coinMoneyRate?:any
    dividendLevelConfig?:any
    dividendEnable?:any
}

export interface updateActivityCoinConfigParam {
    joinCoin?:any
    activityCycle?:any
    dailyTaskInterval?:any
    singleTaskReceiveLowerLimit?:any
    singleTaskReceiveType?:any
    openDays?:any
    enable?:any
    enableDailyTaskInterval?:any
    singleTaskReceiveChangeRate?:any
    singleTaskReceiveUpperLimit?:any
    targetCoin?:any
    daylyTaskSize?:any
}

export interface updateWithdrawConfigParam {
    rate?:any
    enable?:any
    commission?:any
    remark?:any
}

export interface updateUserAuthConfigParam {
    accessKeyId?:any
    endpoint?:any
    autoApply?:any
    productCode?:any
    enable?:any
    sceneId?:any
    enableWithdraw?:any
    callbackUrl?:any
    secret?:any
    certifyId?:any
}

export interface updateTestAccountConfigParam {
    enable?:any
    userId?:any
    account?:any
    msgCode?:any
}
// -------接口列------

/**
 * [后台]保存更新系统配置
 * @notes 
 */
export function updateConfig(param:updateConfigParam) {
    return post('/system/sysConfig/updateConfig', param);
}

/**
 * [后台]保存更新分金币活动配置
 * @notes 
 */
export function updateActivityCoinConfig(param:updateActivityCoinConfigParam) {
    return post('/system/sysConfig/updateActivityCoinConfig', param);
}

/**
 * [公共]加载用户协议
 * @notes 
 */
export function loadArticleProtocol() {
    return get('/system/sysConfig/loadArticleProtocol');
}

/**
 * [后台]保存更新分佣配置
 * @notes 
 */
export function updateAppDividendConfig(param:updateAppDividendConfigParam) {
    return post('/system/sysConfig/updateAppDividendConfig', param);
}

/**
 * [公共]加载注销账号显示
 * @notes 
 */
export function loadAccountCancellation() {
    return get('/system/sysConfig/loadAccountCancellation');
}

/**
 * [后台]保存更新用户实名配置
 * @notes 
 */
export function updateUserAuthConfig(param:updateUserAuthConfigParam) {
    return post('/system/sysConfig/updateUserAuthConfig', param);
}

/**
 * [后台]加载分金币活动配置
 * @notes 
 */
export function loadActivityCoinConfig() {
    return get('/system/sysConfig/loadActivityCoinConfig');
}

/**
 * [后台]加载后台WEB配置
 * @notes 
 */
export function loadAdminWebConfig() {
    return get('/system/sysConfig/loadAdminWebConfig');
}

/**
 * [公共]加载隐私政策
 * @notes 
 */
export function loadArticlePrivacy() {
    return get('/system/sysConfig/loadArticlePrivacy');
}

/**
 * [后台]更新保存APP系统设置
 * @notes 
 */
export function updateSysAppConfig(param:updateSysAppConfigParam) {
    return post('/system/sysConfig/updateSysAppConfig', param);
}

/**
 * [公共]加载金币瓜分规则
 * @notes 
 */
export function loadActivityCoinRoles() {
    return get('/system/sysConfig/loadActivityCoinRoles');
}

/**
 * [公共]加载提现规则信息
 * @notes 
 */
export function loadWithdrawRoles() {
    return get('/system/sysConfig/loadWithdrawRoles');
}

/**
 * [公共]加载APP系统设置
 * @notes 
 */
export function loadSysAppConfig() {
    return get('/system/sysConfig/loadSysAppConfig');
}

/**
 * [后台]加载APP分佣配置
 * @notes 
 */
export function loadAppDividendConfig() {
    return get('/system/sysConfig/loadAppDividendConfig');
}

/**
 * [后台]保存提现配置
 * @notes 
 */
export function updateWithdrawConfig(param:updateWithdrawConfigParam) {
    return post('/system/sysConfig/updateWithdrawConfig', param);
}

/**
 * [后台]加载用户实名配置
 * @notes 
 */
export function loadUserAuthConfig() {
    return get('/system/sysConfig/loadUserAuthConfig');
}

/**
 * [后台]保存更新后台WEB配置
 * @notes 
 */
export function updateAdminWebConfig(param:updateAdminWebConfigParam) {
    return post('/system/sysConfig/updateAdminWebConfig', param);
}

/**
 * [后台]提现配置
 * @notes 
 */
export function loadWithdrawConfig() {
    return get('/system/sysConfig/loadWithdrawConfig');
}

/**
 * [公共]加载活动规则
 * @notes 
 */
export function loadActivityRoles() {
    return get('/system/sysConfig/loadActivityRoles');
}

/**
 * [后台]保存更新测试账号配置
 * @notes 
 */
export function updateTestAccountConfig(param:updateTestAccountConfigParam) {
    return post('/system/sysConfig/updateTestAccountConfig', param);
}

/**
 * [后台]加载测试账号配置
 * @notes 
 */
export function loadTestAccountConfig() {
    return get('/system/sysConfig/loadTestAccountConfig');
}

