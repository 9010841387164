
import { loadActivityCoinConfig, updateActivityCoinConfig } from '@/api/system/sysConfig'
import { Component,Vue } from 'vue-property-decorator'

@Component({
    name: 'ActivityCoinConfig'
})
export default class extends Vue {

    formParam:{
        enable?:any
        activityCycle?:any
        dailyTaskInterval?:any
        singleTaskReceiveLowerLimit?:any
        singleTaskReceiveType?:any
        openDays?:any
        enableDailyTaskInterval?:any
        singleTaskReceiveChangeRate?:any
        singleTaskReceiveUpperLimit?:any
        targetCoin?:any
        daylyTaskSize?:any
    } = {}

    mounted(){
        loadActivityCoinConfig().then(res=>{
            this.formParam = res.data
        })
    }

    handleUpdate(){
        updateActivityCoinConfig(this.formParam).then(res=>{
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
        });
    }
}
